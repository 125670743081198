import React from "react"

import { useLanguage } from "@hooks/useLanguage"
import { useShopify } from "@hooks/useShopify"

export const withProductFormAddOns = Component => ({ name = `ProductFormAddOns`, active, setActive, updateAddOn, product }) => {
  const { useProducts } = useShopify()
  const locales = useLanguage(`products`)

  const handles = product?.tags?.filter(tag => tag.includes(`add:`)).map(tag => tag.replace(`add:`, ``)) || []
  const products = useProducts({ handles, firstVariants: 1 })

  // Remove "s" suffix for singular gown
  let productType = product?.productType ? product.productType.toLowerCase() : ""
  if (productType === "gowns") productType = "gown"

  Component.displayName = name
  return (
    <Component
      active={active}
      locales={locales}
      product={product}
      products={products}
      productType={productType}
      setActive={setActive}
      updateAddOn={updateAddOn}
    />
  )
}
