import React from "react"

import { Variant } from "../../../../types"

import { withProductFormPrice } from "./withProductFormPrice"
import { Money } from "../../Money"
import { CustomerPaymentsInformationPopup } from "../../Customer/Payments/Popup/CustomerPaymentsInformationPopup"
import { CompareAtPrice, StyledP, ProductPriceWrapper, PaymentLabel, PaymentLinkLabel } from "./ProductFormStyles"

interface Props {
  customPaymentEnabled: boolean
  customPaymentDescription: string
  locales: any
  paymentTerms: any
  setShowPopup: any
  showPopup: any
  variant: Variant
}

export const ProductFormPrice = withProductFormPrice(
  ({ customPaymentDescription, customPaymentEnabled, locales, paymentTerms, setShowPopup, showPopup, variant }: Props) => (
    <ProductPriceWrapper>
      <StyledP>

        <Money amount={variant?.priceV2} />
        {Number(variant?.compareAtPriceV2?.amount) > Number(variant?.priceV2?.amount) && (
          <CompareAtPrice compareAtPrice>
            <Money amount={variant?.compareAtPriceV2} />
          </CompareAtPrice>
        )}
      </StyledP>

      {customPaymentEnabled && paymentTerms && (
        <div>
          <PaymentLabel>
            {`${paymentTerms} `}
            <PaymentLinkLabel onClick={() => setShowPopup(true)}>{locales.productLink1}</PaymentLinkLabel>
          </PaymentLabel>
        </div>
      )}

      <CustomerPaymentsInformationPopup active={showPopup} setActive={setShowPopup} content={customPaymentDescription} />
    </ProductPriceWrapper>
  )
)
