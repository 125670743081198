import React from "react"

import { useCore } from "@hooks/useCore"

export const withProductFormAttributes = Component => ({ name = `ProductFormAttributes`, attributes, customAttributes, setCustomAttributes }) => {
  const {
    helpers: { handleize },
  } = useCore()

  const handleAttributeChange = event => {
    const attribute = {}
    attribute[event.target.name] = event.target.value

    const attributes = {
      ...customAttributes,
      ...attribute,
    }

    setCustomAttributes(attributes)
  }

  Component.displayName = name
  return <Component attributes={attributes} customAttributes={customAttributes} handleAttributeChange={handleAttributeChange} handleize={handleize} />
}
