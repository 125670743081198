import React from "react"
import { Link } from "gatsby"

import { Product } from "../../../../types"

import { withProductForm } from "./withProductForm"
import { ProductContentAdditional } from "../Content/ProductContentAdditional"
import { Booking } from "../../Booking/Booking"
import { ProductContentLinks } from "../Content/ProductContentLinks"
import { ProductContentRushFee } from "../Content/ProductContentRushFee"
import { ProductContentSizeGuide } from "../Content/ProductContentSizeGuide"
import { ProductContentSizeGuideButton } from "../Content/ProductContentSizeGuideButton"
import { ProductContent } from "../Content/ProductContent"
import { ProductContentTimeline } from "../Content/ProductContentTimeline"
import { ProductFormAddOns } from "./ProductFormAddOns"
import { ProductFormAttributes } from "./ProductFormAttributes"
import { ProductFormInputs } from "./ProductFormInputs"
import { ProductFormNotify } from "./ProductFormNotify"
import { ProductFormPrice } from "./ProductFormPrice"
import { RichText } from "../../RichText/RichText"
import { ProductVariants } from "../Variants/ProductVariants"
import { WishlistButton } from "../../Wishlist/Button/WishlistButton"
import { Breadcrumb } from "../../Breadcrumb/Breadcrumb"
import { FormContent, Description, Vendor, ProductTitle, WishlistDesktop, BreadcrumbWrapper, StyledPrimaryButton } from "./ProductFormStyles"

interface Props {
  adding: boolean
  additionalContent: any
  addOns: any
  addOnsActive: boolean
  booking: any
  content: any
  customAttributes: any
  customisedAttributes: any
  customPaymentDescription: any
  handleAddToCart: any
  handleQuantityChange: any
  hasMultipleVariants: boolean
  location: any
  notify: any
  onedaypayActive: boolean
  paths: any
  popupContent: any
  product: Product
  quantity: number
  rushActive: any
  rushFee: boolean
  rushFeeAdded: boolean
  rushFeeProduct: Product
  rushFeeOptionLimited: any
  selectedOptions: any
  settings: any
  setAdditionalContent: any
  setAddOns: any
  setAddOnsActive: any
  setBooking: any
  setContentPopup: any
  setCustomAttributes: any
  setNotify: any
  setRushFee: any
  setRushActive: any
  setSelectedOptions: any
  setSizeGuide: any
  setTimeline: any
  setWeeks: any
  sizeChart: any
  sizeGuide: any
  splitSizing: any
  template: any
  timeline: any
  timelineTag: any
  isPreorder: boolean
  trackedCustomAttributes: any
  trackedTimeline: any
  updateAddOn: any
  variant: any
  variantSelected: any
  weeks: any
}

export const ProductForm = withProductForm(
  ({
    adding,
    additionalContent,
    addOns,
    addOnsActive,
    booking,
    content,
    customAttributes,
    customisedAttributes,
    customPaymentDescription,
    handleAddToCart,
    handleQuantityChange,
    hasMultipleVariants,
    notify,
    onedaypayActive,
    paths,
    popupContent,
    product,
    quantity,
    rushActive,
    rushFee,
    rushFeeAdded,
    rushFeeProduct,
    rushFeeOptionLimited,
    settings,
    selectedOptions,
    setAdditionalContent,
    setAddOns,
    setAddOnsActive,
    setBooking,
    setContentPopup,
    setCustomAttributes,
    setNotify,
    setRushFee,
    setRushActive,
    setSelectedOptions,
    setSizeGuide,
    setTimeline,
    setWeeks,
    sizeChart,
    sizeGuide,
    splitSizing,
    template,
    timeline,
    timelineTag,
    isPreorder,
    trackedCustomAttributes,
    trackedTimeline,
    updateAddOn,
    variant,
    variantSelected,
    weeks,
  }: Props) => (
    <>
      <FormContent>
        <Description>
          {paths.length > 1 && (
            <BreadcrumbWrapper>
              <Breadcrumb paths={paths} />
            </BreadcrumbWrapper>
          )}
          <Vendor as={`h3`}>{product.vendor}</Vendor>
          <ProductTitle as={`h1`}>
            <RichText html={product.title} />
          </ProductTitle>

          <ProductFormPrice
            product={product}
            variant={variant}
            customPaymentEnabled={onedaypayActive}
            customPaymentDescription={customPaymentDescription}
          />

          <ProductContent product={product} content={content} template={template} />

          <ProductContentSizeGuideButton setActive={setSizeGuide} sizeChart={sizeChart} />

          {template?.enquiry?.title && template?.enquiry?.url ? (
            <>
              <StyledPrimaryButton as={Link} full title={template?.enquiry?.title} to={template?.enquiry?.url}>
                {template?.enquiry?.title}
              </StyledPrimaryButton>
            </>
          ) : (
            <>
              {product?.variants?.length > 0 && (
                <>
                  {hasMultipleVariants && (
                    <ProductVariants
                      attributes={settings.products.splitSizing.dimentions}
                      customAttributes={customAttributes}
                      product={product}
                      selectedOptions={selectedOptions}
                      setCustomAttributes={setCustomAttributes}
                      setNotify={setNotify}
                      setSelectedOptions={setSelectedOptions}
                      setSizeGuide={setSizeGuide}
                      sizeChart={sizeChart}
                      splitSizing={splitSizing}
                      shippingLabel={timelineTag ? `Ships in ${timelineTag.split(`:`)[1] / 30} months` : `Ready to ship`}
                    />
                  )}

                  <ProductFormAddOns active={addOnsActive} product={product} setActive={setAddOnsActive} updateAddOn={updateAddOn} />

                  <ProductFormAttributes
                    attributes={customisedAttributes}
                    customAttributes={customAttributes}
                    setCustomAttributes={setCustomAttributes}
                  />
                  <ProductFormInputs
                    adding={adding}
                    addOns={addOns}
                    customAttributes={customAttributes}
                    handleAddToCart={handleAddToCart}
                    handleQuantityChange={handleQuantityChange}
                    quantity={quantity}
                    product={product}
                    setNotify={setNotify}
                    trackedCustomAttributes={trackedCustomAttributes}
                    variantSelected={variantSelected}
                    variant={variant}
                  />
                </>
              )}
            </>
          )}

          <ProductContentTimeline
            rushActive={rushActive}
            rushFeeAdded={rushFeeAdded}
            rushFeeProduct={rushFeeProduct}
            setRushActive={setRushActive}
            setRushFee={setRushFee}
            setTimeline={setTimeline}
            setWeeks={setWeeks}
            timeline={timeline}
            tag={timelineTag}
            isPreorder={isPreorder}
            updateAddOn={updateAddOn}
            rushFeeOptionLimited={rushFeeOptionLimited}
            variant={variant}
          />

          <ProductContentLinks setBooking={setBooking} setContent={setAdditionalContent} setPopup={setContentPopup} />
        </Description>

        <WishlistDesktop>
          <WishlistButton item={product} />
        </WishlistDesktop>
      </FormContent>

      <ProductContentAdditional active={popupContent} content={additionalContent} setActive={setContentPopup} />

      <Booking active={booking ? true : false} booking={booking} setActive={setBooking} />

      <ProductFormNotify active={notify} product={product} setActive={setNotify} variant={variant}>
        {hasMultipleVariants && (
          <ProductVariants
            attributes={settings.products.splitSizing.dimentions}
            customAttributes={customAttributes}
            popup
            product={product}
            selectedOptions={selectedOptions}
            setCustomAttributes={setCustomAttributes}
            setNotify={setNotify}
            setSelectedOptions={setSelectedOptions}
            setSizeGuide={setSizeGuide}
            sizeChart={sizeChart}
            splitSizing={splitSizing}
          />
        )}
      </ProductFormNotify>

      <ProductContentSizeGuide active={sizeGuide} content={sizeChart} setActive={setSizeGuide} />

      <ProductContentRushFee
        active={rushFee}
        product={product}
        rushFeeAdded={rushFeeAdded}
        rushFeeProduct={rushFeeProduct}
        setAddOns={setAddOns}
        setRushFee={setRushFee}
        setTimeline={setTimeline}
        setWeeks={setWeeks}
        timeline={timeline}
        trackedTimeline={trackedTimeline}
        updateAddOn={updateAddOn}
        weeks={weeks}
      />
    </>
  )
)
