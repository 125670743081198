import React, { useEffect, useState } from "react"

import { useApp } from "@hooks/useApp"
import { useCore } from "@hooks/useCore"
import { useRoutes } from "@hooks/useRoutes"
import { useScrollHidden } from "@hooks/useScroll"
import { useShopify } from "@hooks/useShopify"
import { useSanity } from "@hooks/useSanity"

export const withProduct = Component => ({
  name = `Product`,
  grouped,
  product: staticProduct,
  live: liveData,
  loading,
  template: productTemplate,
  ...props
}) => {
  const {
    config: {
      settings: { params },
    },
  } = useApp()
  const {
    helpers: { encodeShopifyId },
  } = useCore()
  const { getUrlParameter, routeResolver } = useRoutes()
  const { excludeHidden, productNormaliser, productWithGroupedOptionsNormaliser } = useShopify()
  const { textNormaliser } = useSanity()
  const [live, setLive] = useState(null)
  const [zoom, setZoom] = useState(false)

  const product = live?.product || staticProduct
  const { recommendations: productRecommendations } = live || {}
  const item = productWithGroupedOptionsNormaliser({
    product,
    grouped: live?.grouped || grouped?.nodes || [],
  })

  const content = {
    ...staticProduct,
    details: textNormaliser(staticProduct?._rawDetails),
    process: textNormaliser(staticProduct?._rawProcess),
    related: staticProduct?.completeTheLook?.map(product => product?.shopify?.handle) || [],
    selectedVariant: encodeShopifyId(getUrlParameter(params?.variant), `ProductVariant`),
    template: {
      enquiry:
        productTemplate?.enquiryTag && productTemplate?.enquiryLink?.link?.length > 0 && product?.tags?.includes(productTemplate?.enquiryTag)
          ? {
              title: productTemplate?.enquiryLink?.title?.enAu,
              url: routeResolver({ item: productTemplate.enquiryLink?.link?.[0]?.link }),
            }
          : null,
      process: productTemplate?._rawProcess?.map(item => ({
        tag: item.productTag,
        content: textNormaliser(item.content),
      })),
      sizeChart: productTemplate._rawSizeGuide.map(item => ({
        productType: item.productType,
        measuring: textNormaliser(item.measuring),
        table: {
          rows: item.sizeGuideTable.rows.map(block => ({
            columns: block.cells,
          })),
        },
        image: item?.image,
        videoFile: item?.videoFile,
        video: item?.video?.link
          ? {
              link: item.video.link,
            }
          : null,
      })),
    },
  }
  // const recommendations = excludeHidden(productRecommendations).map(product => productNormaliser(product))

  let recommendations = []
  product.collections?.edges.forEach((edge: any) => {
    if (edge.node.handle === "all") return

    edge.node.products.edges.forEach(element => {
      recommendations.push(element.node)
    })
  })
  recommendations.sort(() => Math.random() - 0.5)

  useEffect(() => {
    if (!live) setLive(liveData)
  }, [liveData])

  useEffect(() => {
    useScrollHidden(zoom)
  }, [zoom])

  Component.displayName = name
  return <Component {...props} content={content} loading={loading} product={item} recommendations={recommendations} setZoom={setZoom} zoom={zoom} />
}
