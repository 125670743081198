import React from "react"

export const withProductImage = Component => ({ name = `ProductImage`, active, images: productImages, setActive, title, zoomed }) => {
  const [activeImage, setActiveImage] = React.useState(0)
  const images = productImages.filter(previewImage => previewImage)

  Component.displayName = name
  return (
    <Component
      active={active}
      images={images}
      setActive={setActive}
      title={title}
      zoomed={zoomed}
      setActiveImage={setActiveImage}
      activeImage={activeImage}
    />
  )
}
