import React from "react"

import { Product as ProductTypes } from "../../../types"

import { withProduct } from "./withProduct"
import { ProductForm } from "./Form/ProductForm"
import { ProductImage } from "./Image/ProductImage"
import { ProductUpsell } from "./Upsell/ProductUpsell"
import { WishlistButton } from "../Wishlist/Button/WishlistButton"
import { WishlistMobile, Section, Images } from "./ProductStyles"

interface Props {
  content: any
  loading: boolean
  location: any
  product: ProductTypes
  recommendations: any
  setZoom: any
  template: any
  zoom: boolean
}

export const Product = withProduct(({ content, location, loading, product, recommendations, setZoom, zoom }: Props) => (
  <>
    <Section>
      <Images>
        <ProductImage title={product.title} images={product.media} setActive={setZoom} active={zoom} />
        <WishlistMobile>
          <WishlistButton item={product} />
        </WishlistMobile>
      </Images>

      <ProductForm content={content} loading={loading} location={location} product={product} template={content?.template} />
    </Section>

    <ProductUpsell product={product} related={content?.related} recommendations={recommendations} />
    <ProductImage title={product.title} images={product.media} setActive={setZoom} active={zoom} zoomed />
  </>
))
