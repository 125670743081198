import React from "react"

import { withProductVariantsOption } from "./withProductVariantsOption"
import { Dropdown } from "../../Styled/Dropdown/Dropdown"
import { Wrapper } from "../ProductStyles"

interface Props {
  disabled: boolean
  findVariant: any
  linkText: any
  onChange: any
  onLinkClick: any
  option: any
  optionLabel: any
  options: any
  popup: any
  value: any
}

export const ProductVariantsOption = withProductVariantsOption(
  ({ disabled, linkText, onChange, onLinkClick, option, optionLabel, options, popup, value }: Props) => (
    <Wrapper popup={popup}>
      <Dropdown
        disabled={disabled}
        key={option.id}
        label={optionLabel ? `${optionLabel} ${option.name ? option.name.toLowerCase() : ``}` : option.name}
        linkText={linkText}
        name={option.name}
        onChange={onChange}
        onLinkClick={onLinkClick}
        options={options}
        value={value}
      />
    </Wrapper>
  )
)
