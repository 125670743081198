import React from "react"

import { Product } from "../../../../types"

import { withProductFormNotify } from "./withProductFormNotify"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"
import { Popup } from "../../Modals/Popup/Popup"
import { FormError } from "../../Styled/Form"
import { PrimaryButton } from "../../Styled/Button"
import { Content, CheckboxWrapper, StyledH4, StyledMessage, StyledSmall, StyledInput } from "./ProductFormStyles"

interface Props {
  acceptsMarketing: boolean
  active: boolean
  children: any
  customer: any
  email: any
  errors: any
  field: any
  handleSubscribe: any
  handleSubmit: any
  locales: any
  loading: boolean
  sent: boolean
  product: Product
  setActive: any
  setEmail: any
}

export const ProductFormNotify = withProductFormNotify(
  ({
    acceptsMarketing,
    active,
    children,
    customer,
    email,
    errors,
    field,
    handleSubscribe,
    handleSubmit,
    loading,
    sent,
    locales,
    product,
    setActive,
    setEmail,
  }: Props) => (
    <Popup active={active} setActive={setActive}>
      {sent ? (
        <Content as={`div`}>
          <StyledH4>{locales.notifySuccessTitle}</StyledH4>
          <StyledMessage>{locales.notifySuccessDescription.replace(`{product}`, product?.title || `this item`)}</StyledMessage>
        </Content>
      ) : (
        <Content onSubmit={handleSubmit}>
          <StyledH4>{locales.notifyTitle}</StyledH4>
          <StyledInput
            disabled={loading}
            ref={field}
            name={`email`}
            onChange={({ target: { value } }) => setEmail(value)}
            placeholder={locales.emailAddress}
            type={`text`}
            value={email}
          />

          {children}

          {!customer?.email && (
            <CheckboxWrapper>
              <Checkbox checked={acceptsMarketing} externalState onChange={handleSubscribe} large>
                {locales.subscribe}
              </Checkbox>
            </CheckboxWrapper>
          )}

          <StyledSmall>{locales.terms}</StyledSmall>
          <PrimaryButton disabled={loading} full={`true`} type={`submit`}>
            {locales.send}
          </PrimaryButton>

          {errors?.length > 0 && errors?.map(({ code, message }) => <FormError key={code}>{message}</FormError>)}
        </Content>
      )}
    </Popup>
  )
)
