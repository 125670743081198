import React from "react"

import { withProductFormAttributes } from "./withProductFormAttributes"
import { Textarea } from "../../Styled/Textarea"
import { Attribute, Attributes, StyledLabel, StyledAttributeInput } from "./ProductFormStyles"

interface Props {
  attributes: any
  customAttributes: any
  handleAttributeChange: any
  handleize: any
}

export const ProductFormAttributes = withProductFormAttributes(
  ({ attributes, customAttributes, handleAttributeChange, handleize }: Props) =>
    attributes?.length > 0 && (
      <Attributes>
        {attributes.map(({ label, maxLength, placeholder, type }) => (
          <Attribute key={handleize(label)}>
            <StyledLabel>{`${label}`}</StyledLabel>

            {type === `textarea` ? (
              <Textarea
                maxLength={maxLength}
                name={handleize(label)}
                placeholder={placeholder}
                onChange={handleAttributeChange}
                value={customAttributes[handleize(label)] || ``}
              />
            ) : (
              <StyledAttributeInput
                maxLength={maxLength}
                name={handleize(label)}
                placeholder={placeholder}
                onChange={handleAttributeChange}
                value={customAttributes[handleize(label)] || ``}
              />
            )}
          </Attribute>
        ))}
      </Attributes>
    )
)
