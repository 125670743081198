import React from "react"

import { useApp } from "@hooks/useApp"
import { useLanguage } from "@hooks/useLanguage"

export const withProductVariants = Component => ({
  name = `ProductVariants`,
  attributes,
  customAttributes,
  popup,
  product,
  selectedOptions,
  setCustomAttributes,
  setNotify,
  setSelectedOptions,
  setSizeGuide,
  shippingLabel,
  sizeChart,
  splitSizing,
}) => {
  const {
    config: { settings },
  } = useApp()
  const locales = {
    ...useLanguage(`cart`),
    ...useLanguage(`products`),
  }

  const findVariant = (selectedOptions, variants) => {
    const currentOptions = [...selectedOptions]

    return (
      variants.find(
        ({ selectedOptions: variantOptions }) =>
          variantOptions?.filter(
            variantOption => variantOption.value === currentOptions.find(currentOption => currentOption.name === variantOption.name)?.value
          )?.length === selectedOptions?.length
      ) || null
    )
  }

  const handleOptionChange = (value, name) => {
    const newSelectedOptions = selectedOptions.map(selectedOption => (selectedOption.name === name ? { ...selectedOption, value } : selectedOption))

    setSelectedOptions(newSelectedOptions)
  }

  const swapOptions = (options, optionsName1 = "", optionsName2 = "", optionsName2Alt = "") => {
    if (!Array.isArray(options)) {
      return options
    }
    const index1 = options.findIndex(opt => opt.name.toLowerCase() === optionsName1.toLowerCase())
    let index2 = options.findIndex(opt => opt.name.toLowerCase() === optionsName2.toLowerCase())

    if (index2 < 0) {
      index2 = options.findIndex(opt => opt.name.toLowerCase() === optionsName2Alt.toLowerCase())
    }

    if (index1 < 0 || index2 < 0 || index2 < index1) {
      return options
    }

    const newOptions = [...options]
    const temp = newOptions[index1]
    newOptions[index1] = newOptions[index2]
    newOptions[index2] = temp

    return newOptions
  }

  const options = swapOptions(product.options, "size", "color", "colour")

  Component.displayName = name
  return (
    <Component
      attributes={attributes}
      customAttributes={customAttributes}
      findVariant={findVariant}
      handleOptionChange={handleOptionChange}
      locales={locales}
      options={options}
      popup={popup}
      product={product}
      selectedOptions={selectedOptions}
      setCustomAttributes={setCustomAttributes}
      setNotify={setNotify}
      setSizeGuide={setSizeGuide}
      settings={settings}
      shippingLabel={shippingLabel}
      sizeChart={sizeChart}
      splitSizing={splitSizing}
    />
  )
}
