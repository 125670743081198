import React, { Fragment } from "react"

import { Product } from "../../../../types"

import { withProductVariants } from "./withProductVariants"
import { ProductFormGiftCard } from "../Form/ProductFormGiftCard"
import { ProductFormSizing } from "../Form/ProductFormSizing"
import { ProductVariantsOption } from "./ProductVariantsOption"

interface Props {
  attributes: any
  customAttributes: any
  findVariant: any
  handleOptionChange: any
  locales: any
  options: Array<any>
  product: Product
  popup: any
  settings: any
  selectedOptions: any
  splitSizing: any
  setCustomAttributes: any
  setNotify: any
  setSizeGuide: any
  sizeChart: any
  shippingLabel: string
}

export const ProductVariants = withProductVariants(
  ({
    attributes,
    customAttributes,
    findVariant,
    handleOptionChange,
    locales,
    options,
    popup,
    product,
    sizeChart,
    selectedOptions,
    setCustomAttributes,
    setSizeGuide,
    setNotify,
    settings,
    shippingLabel,
    splitSizing,
  }: Props) => (
    <>
      <ProductFormGiftCard label={locales.variantOptionPrefix} product={product} />

      {options.map((option, index) => (
        <Fragment key={option.id?.toString()}>
          <ProductVariantsOption
            index={index}
            hasColourOption={options.find(opt => [`color`, `colour`]?.includes(opt.name?.toLowerCase()))}
            findVariant={findVariant}
            linkText={option?.name?.toLowerCase() === settings.products.splitSizing.sizeOptionName && sizeChart ? locales.sizeGuide : null}
            lowStockLabel={locales.variantLowStock}
            noStockLabel={locales.variantNoStock}
            onChange={handleOptionChange}
            onLinkClick={option?.name?.toLowerCase() === settings.products.splitSizing.sizeOptionName && sizeChart ? () => setSizeGuide(true) : null}
            optionLabel={locales.variantOptionPrefix}
            option={option}
            popup={popup}
            priceRange={product.priceRange}
            productType={product.productType}
            selectedOptions={selectedOptions}
            setNotify={setNotify}
            soldOutLabel={locales.variantSoldOut}
            variants={product.variants}
            shippingLabel={shippingLabel}
          />

          {option?.name?.toLowerCase() === settings.products.splitSizing.sizeOptionName ? (
            <ProductFormSizing
              active={splitSizing}
              attributes={attributes}
              customAttributes={customAttributes}
              setCustomAttributes={setCustomAttributes}
            />
          ) : null}
        </Fragment>
      ))}
    </>
  )
)
