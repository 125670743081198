import React from "react"
import { graphql } from "gatsby"

import { useCore } from "@hooks/useCore"
import { useShopify } from "@hooks/useShopify"
import { useQueries } from "@hooks/useQueries"

import { Product as Template } from "@components/Product/Product"

export const query = graphql`
  query($handle: String!, $parentQuery: String) {
    product: sanityProduct(shopify: { shopifyHandle: { eq: $handle } }) {
      ...GatsbySanityProductFragment
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      _rawDetails(resolveReferences: { maxDepth: 10 })
      _rawProcess(resolveReferences: { maxDepth: 10 })
      completeTheLook {
        shopify {
          handle: shopifyHandle
        }
      }
      primaryCollection {
        shopify {
          handle: shopifyHandle
        }
        title
      }
    }
    grouped: allSanityProduct(filter: { tags: { eq: $parentQuery } }) {
      nodes {
        ...GatsbySanityProductFragment
      }
    }
    template: sanityProductDetailsPage {
      _rawProcess(resolveReferences: { maxDepth: 10 })
      _rawSizeGuide(resolveReferences: { maxDepth: 10 })
      enquiryLink: _rawEnquiryLink(resolveReferences: { maxDepth: 3 })
      enquiryTag
    }
  }
`

export default ({ data, ...props }) => {
  const {
    helpers: { encodeShopifyId, isBrowser },
  } = useCore()
  const {
    queries: { PRODUCT_BY_HANDLE },
  } = useQueries()
  const { useQuery } = useShopify()
  const { product } = data

  const { data: live, loading, error } =
    isBrowser && product?.shopify?.handle?.length
      ? useQuery(PRODUCT_BY_HANDLE, {
          fetchPolicy: `cache-and-network`,
          variables: {
            id: encodeShopifyId(product.shopify.id, `Product`),
            handle: product.shopify.handle,
            parentQuery: `tag:"parent:${product.shopify.handle}"`,
            firstCollections: 5,
            firstImages: 5,
            firstMedia: 0,
            metafieldIdentifiers: [],
            firstVariants: 100,
          },
        })
      : {}

  if (error) console.error(error)

  if (!product || !product.shopify || !product.shopify.handle) {
    // Handle case where product data is missing
    return <div>Product not found</div>
  }

  return <Template {...props} {...data} live={live} loading={loading} />
}
