import React, { useEffect, useRef, useState } from "react"

import { useCore } from "@hooks/useCore"
import { useLanguage } from "@hooks/useLanguage"

export const withProductFormSizing = Component => ({ name = `ProductFormSizing`, active, attributes, customAttributes, setCustomAttributes }) => {
  const [splitAttributes, setSplitAttributes] = useState({})
  const {
    helpers: { handleize },
  } = useCore()

  const updateCustomAttributes = () => {
    setCustomAttributes(splitAttributes)
  }

  const fieldRef = useRef()

  const locales = useLanguage(`products`)

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        fieldRef.current?.focus()
      }, 100)
    }
  }, [active])

  Component.displayName = name
  return active ? (
    <Component
      attributes={attributes}
      customAttributes={customAttributes}
      fieldRef={fieldRef}
      handleize={handleize}
      locales={locales}
      setSplitAttributes={setSplitAttributes}
      updateCustomAttributes={updateCustomAttributes}
    />
  ) : null
}
