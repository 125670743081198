import React from "react"
import { Link } from "gatsby"

import { withProductContentLinks } from "./withProductContentLinks"
import { Links, LinkItem, StyledButton } from "./ProductContentStyles"

interface Props {
  handlePopup: any
  isBrowser: boolean
  navigation: any
  setBooking: any
}

export const ProductContentLinks = withProductContentLinks(({ handlePopup, isBrowser, navigation, setBooking }: Props) =>
  navigation?.items?.length > 0 ? (
    <Links>
      {navigation.items
        .filter(item => item?.title)
        .map(item => (
          <LinkItem key={item.title}>
            {item.link.url.includes(`calendly`) ? (
              <StyledButton onClick={() => setBooking(item.link.url)}>{item.title}</StyledButton>
            ) : item.link.url.includes(`gorgias`) ? (
              <StyledButton onClick={isBrowser ? () => (window.GorgiasChat ? window.GorgiasChat.open() : null) : null}>{item.title}</StyledButton>
            ) : item.link.type === `policy` && item?.content ? (
              <StyledButton onClick={() => handlePopup(item.content)}>{item.title}</StyledButton>
            ) : item.link.type !== `customLink` ? (
              <StyledButton as={Link} to={item.link.url}>
                {item.title}
              </StyledButton>
            ) : (
              <StyledButton as={`a`} href={item.link.url} target={`_blank`}>
                {item.title}
              </StyledButton>
            )}
          </LinkItem>
        ))}
    </Links>
  ) : null
)
