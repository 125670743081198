import React from "react"

export const withProductVariantsOption = Component => ({
  name = `ProductVariantsOption`,
  hasColourOption,
  linkText,
  shippingLabel,
  option,
  onChange,
  onLinkClick,
  optionLabel,
  popup,
  selectedOptions,
  setNotify,
  soldOutLabel,
  variants,
}) => {
  const findVariant = (option, value, selectedOptions, variants) => {
    const currentOptions = [...selectedOptions.filter(({ name }) => name !== option?.name), { ...option, value }]

    return (
      variants.find(
        ({ selectedOptions: variantOptions }) =>
          variantOptions?.filter(
            variantOption => variantOption.value === currentOptions.find(currentOption => currentOption.name === variantOption.name)?.value
          )?.length === selectedOptions?.length
      ) || null
    )
  }

  const checkDisabled = option => {
    if (option?.name === "Size" && hasColourOption) {
      const selectedColor = selectedOptions.find(opt => opt.name === "Color" || opt.name === "Colour")
        ? selectedOptions.find(opt => opt.name === "Color" || opt.name === "Colour").value
        : null
      return !selectedColor
    }

    return false
  }

  const createVariantOptions = option =>
    option.values.map(value => {
      const variant = findVariant(option, value, selectedOptions, variants)
      const variantIsUnavailable = variant && !variant.availableForSale
      const variantStock = variant?.quantityAvailable
      const prefix =
        selectedOptions && typeof selectedOptions === `object`
          ? selectedOptions
              .reduce((result, opt) => {
                if (opt.name !== option.name) {
                  result.push(opt.value)
                }
                return result
              }, [])
              .join(" ")
          : ""

      const obj = {
        disabled: variantIsUnavailable,
        button: {
          label:
            option?.name?.toLowerCase() === "size"
              ? variantIsUnavailable
                ? `${prefix ? `${prefix}:` : ""} ${soldOutLabel}`
                : variantStock && typeof variantStock === `number` && variantStock > 0
                ? "Ready to ship"
                : shippingLabel
              : "",
          onClick: variantIsUnavailable ? () => setNotify(true) : null,
        },
        label: value,
        value: value,
      }

      return obj
    })

  const value = selectedOptions?.find(opt => opt.name === option.name)?.value
  const disabled = checkDisabled(option)
  const options = createVariantOptions(option)

  Component.displayName = name
  return (
    <Component
      disabled={disabled}
      linkText={linkText}
      onChange={onChange}
      onLinkClick={onLinkClick}
      option={option}
      optionLabel={optionLabel}
      options={options}
      popup={popup}
      value={value}
    />
  )
}
