import { css } from "@emotion/core"
import styled from "@emotion/styled"
import tw from "twin.macro"

import IconClose from "static/icons/theme/close.svg"

export const Desktop = tw.div`hidden md:block mt-4-2`
export const Mobile = tw.div`block md:hidden mt-4-2`
export const Close = tw.button`absolute top-1-2 right-1-2 block md:hidden`
export const Icon = tw(IconClose)`block w-2 h-2`
export const Popup = styled.div`
  ${tw`fixed bg-light z-50 inset-0 overflow-x-hidden overflow-y-auto w-full-vw h-full-vh transform transition-all duration-200 ease-cubic`}
  ${props => (props.active ? tw`opacity-100 visible translate-y-0` : tw`opacity-0 invisible translate-y-12`)}
`
export const StyledImage = styled.div`
  ${props => (props.index ? tw`mt-1-2` : ``)}
  ${props =>
    props.zoomed
      ? css`
          cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='40' height='40'%3E%3Cpath fill='none' stroke='currentColor' stroke-linejoin='round' d='M20 12H4'/%3E%3C/svg%3E%0A"),
            zoom-out;
        `
      : css`
          cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='40' height='40'%3E%3Cpath d='M12 4v16m8-8H4' fill='none' fill-rule='evenodd' stroke='currentColor' stroke-linejoin='round'/%3E%3C/svg%3E"),
            zoom-in;
        `}
`
