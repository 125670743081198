import React from "react"

import { withProductUpsell } from "./withProductUpsell"
import { ProductUpsellRecommendations } from "./ProductUpsellRecommendations"
import { ProductUpsellRelated } from "./ProductUpsellRelated"
import { Tabs, Tab } from "../../Styled/Tabs"
import { Upsells, UpsellsContent } from "./ProductUpsellStyles"

interface Props {
  active: string
  locales: any
  recent: Array<any>
  recommendations: Array<any>
  related: Array<any>
  setActive: any
}

export const ProductUpsell = withProductUpsell(
  ({ active, locales, recent, recommendations, related, setActive }: Props) =>
    (recommendations?.length > 0 || recent?.length > 0 || related?.length > 0) && (
      <Upsells as={`section`}>
        <Tabs>
          {recommendations?.length > 0 && (
            <Tab first active={active === `recommendations`} onClick={() => setActive(`recommendations`)}>
              {locales.recommendations}
            </Tab>
          )}
          {recent?.length > 0 && (
            <Tab first={!(recommendations && recommendations.length)} active={active === `recent`} onClick={() => setActive(`recent`)}>
              {locales.recentlyViewed}
            </Tab>
          )}
          {related?.length > 0 && (
            <Tab
              first={!(recommendations && recommendations.length) && !(recent && recent.length)}
              active={active === `related`}
              onClick={() => setActive(`related`)}
            >
              {locales.related}
            </Tab>
          )}
        </Tabs>

        {recommendations?.length > 0 && (
          <UpsellsContent active={active === `recommendations`}>
            <ProductUpsellRecommendations products={recommendations} />
          </UpsellsContent>
        )}
        {recent?.length > 0 && (
          <UpsellsContent active={active === `recent`}>
            <ProductUpsellRelated handles={recent} />
          </UpsellsContent>
        )}
        {related?.length > 0 && (
          <UpsellsContent active={active === `related`}>
            <ProductUpsellRelated handles={related} />
          </UpsellsContent>
        )}
      </Upsells>
    )
)
