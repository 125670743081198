import React, { useState } from "react"

import { useLanguage } from "@hooks/useLanguage"
import { usePaymentPrices } from "@hooks/usePayments"

export const withProductFormPrice = Component => ({
  name = `ProductFormPrice`,
  product,
  variant,
  customPaymentEnabled,
  customPaymentDescription,
}) => {
  const [showPopup, setShowPopup] = useState(false)
  const locales = useLanguage(`payment`)
  const { usePaymentTerms } = usePaymentPrices()

  const paymentTerms = usePaymentTerms(product, variant)

  Component.displayName = name
  return (
    <Component
      customPaymentDescription={customPaymentDescription}
      customPaymentEnabled={customPaymentEnabled}
      locales={locales}
      paymentTerms={paymentTerms}
      showPopup={showPopup}
      setShowPopup={setShowPopup}
      variant={variant}
    />
  )
}
