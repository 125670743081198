import React from "react"

import { useShopify } from "@hooks/useShopify"

export const withProductUpsellRecommendations = Component => ({ name = `ProductUpsellRelated`, products }) => {
  const handles = products.slice(0, 3).map(product => product.handle)
  const { excludeHidden, useProducts } = useShopify()

  const items = useProducts({ handles, firstImages: 2, firstMedia: 0, firstVariants: 1 })
  const formattedProducts = excludeHidden(items).slice(0, 3)

  Component.displayName = name
  return <Component products={formattedProducts} />
}
