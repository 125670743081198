import styled from "@emotion/styled"
import tw from "twin.macro"

import PlaySmall from "static/icons/theme/play-small.svg"

export const NavContainer = styled.div`
  ${props => (props.zoomed ? tw`z-60` : tw`z-10`)}
  ${props => (props.active ? tw`opacity-100` : tw`opacity-0`)}
  ${tw`fixed top-0 bottom-0 hidden md:flex flex-col justify-center items-center`}
`
export const NavBar = styled.div`
  ${props => (props.active ? tw`opacity-100` : tw`opacity-50`)}
  ${tw`bg-white h-4 w-0-3 my-0-6 mx-1-6 transition-opacity`}
`
export const NavIconWrapper = styled.div`
  ${tw`text-white h-4 flex items-center transition-opacity`}
`
export const PlayIcon = tw(PlaySmall)`block w-2 h-2`
