import React from "react"

import { withProductFormQuantity } from "./withProductFormQuantity"
import { QuantityWrapper } from "./ProductFormStyles"

interface Props {
  hidden: boolean
  handleQuantityChange: any
  locales: any
  quantity: number
}

export const ProductFormQuantity = withProductFormQuantity(({ hidden, handleQuantityChange, locales, quantity }: Props) => (
  <QuantityWrapper hidden={hidden}>
    <label htmlFor={`quantity`}>{locales.quantity}</label>
    <input type={`number`} id={`quantity`} name={`quantity`} min={`1`} step={`1`} onChange={handleQuantityChange} value={quantity} />
  </QuantityWrapper>
))
