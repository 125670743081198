import React from "react"

import { Product } from "../../../../types"

import { withProductFormAddOns } from "./withProductFormAddOns"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"
import { Money } from "../../Money"
import { Icon, AddOns, AddOn, Label, Trigger } from "./ProductFormStyles"

interface Props {
  active: boolean
  locales: any
  product: Product
  products: Array<Product>
  productType: string
  setActive: any
  updateAddOn: any
}

export const ProductFormAddOns = withProductFormAddOns(({ active, locales, product, products, productType, setActive, updateAddOn }: Props) =>
  products?.length > 0 ? (
    <>
      <Trigger className={`group`} onClick={() => setActive(!active)}>
        <Label>{`${locales.addOns} ${productType}`} </Label>
        <Icon active={active ? `active` : undefined} />
      </Trigger>

      <AddOns active={active ? `active` : undefined}>
        {products.map((item, i) => (
          <AddOn key={i} last={i + 1 === item.length}>
            <Checkbox checked={false} onChange={() => updateAddOn(item)}>
              <span>{item.title} </span>
              <Money amount={item?.variants?.[0]?.priceV2} />
            </Checkbox>
          </AddOn>
        ))}
      </AddOns>
    </>
  ) : null
)
