import React from "react"

import { Product, Variant } from "../../../../types"

import { withProductFormInputs } from "./withProductFormInputs"
import { ProductFormQuantity } from "./ProductFormQuantity"
import { StyledPrimaryButton } from "./ProductFormStyles"

interface Props {
  adding: boolean
  buttonText: string
  handleAddToCart: any
  handleQuantityChange: any
  customAttributes: any
  quantity: any
  product: Product
  setNotify: any
  settings: any
  trackedCustomAttributes: any
  variantSelected: any
  variantSoldOut: boolean
  variant: Variant
}

export const ProductFormInputs = withProductFormInputs(
  ({
    adding,
    buttonText,
    customAttributes,
    handleAddToCart,
    handleQuantityChange,
    product,
    quantity,
    settings,
    setNotify,
    trackedCustomAttributes,
    variantSelected,
    variantSoldOut,
  }: Props) => (
    <>
      <ProductFormQuantity hidden onChange={handleQuantityChange} value={quantity} />
      <StyledPrimaryButton
        full
        disabled={
          !(
            variantSelected &&
            (Object.entries(customAttributes).filter(attr => attr[1] && attr[1] !== ``).length === trackedCustomAttributes.length ||
              product.productType.toLowerCase() !== settings.products.giftCards.type.toLowerCase())
          ) || adding
        }
        onClick={variantSoldOut ? () => setNotify(true) : handleAddToCart}
        type={`submit`}
      >
        {buttonText}
      </StyledPrimaryButton>
    </>
  )
)
