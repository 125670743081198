import React from "react"

import { withProductFormSizing } from "./withProductFormSizing"
import { SplitSizing, SplitAttributes, SplitAttribute, AttributeLabel, AttributeInput, SplitButton } from "./ProductFormStyles"

interface Props {
  attributes: Array<any>
  customAttributes: any
  fieldRef: any
  handleize: any
  locales: any
  setSplitAttributes: any
  splitAttributes: any
  updateCustomAttributes: any
}

export const ProductFormSizing = withProductFormSizing(
  ({ attributes, customAttributes, fieldRef, handleize, locales, setSplitAttributes, splitAttributes, updateCustomAttributes }: Props) =>
    attributes?.length > 0 && (
      <SplitSizing>
        <AttributeLabel>{locales.splitSizeLabel}</AttributeLabel>

        <SplitAttributes>
          {attributes.map((attribute, i) => (
            <SplitAttribute key={handleize(attribute)}>
              <AttributeInput
                name={handleize(attribute)}
                placeholder={attribute.toUpperCase()}
                ref={!i ? fieldRef : null}
                onChange={event => {
                  const attribute = {}
                  attribute[event.target.name] = event.target.value

                  const attributes = {
                    ...splitAttributes,
                    ...attribute,
                  }

                  setSplitAttributes(attributes)
                }}
              />
            </SplitAttribute>
          ))}

          <SplitButton onClick={() => updateCustomAttributes()} disabled={customAttributes === splitAttributes}>
            {locales.splitSizeButton}
          </SplitButton>
        </SplitAttributes>
      </SplitSizing>
    )
)
