import React, { useEffect, useMemo, useState, useRef } from "react"
import { navigate } from "gatsby"

import { useAnalytics } from "@hooks/useAnalytics"
import { useApp } from "@hooks/useApp"
import { useCart } from "@hooks/useCart"
import { useCheckoutContext } from "@hooks/useCheckout"
import { useCore } from "@hooks/useCore"
import { useCustomerDetails } from "@hooks/useCustomer"
import { useRoutes } from "@hooks/useRoutes"
import { useShopify } from "@hooks/useShopify"
import { loadPaymentConfigs, usePaymentData } from "@hooks/usePayments"
import { useLocation } from "@hooks/useLocation"

export const withProductForm = Component => ({ name = `ProductForm`, content, loading, location, product, template }) => {
  const state = location?.state
  const { customPaymentEnabled, customPaymentUsers, customPaymentDescription } = usePaymentData()
  const { checkout } = useCheckoutContext()
  const {
    activeCart,
    setActiveCart,
    activeProduct,
    config: { settings },
  } = useApp()
  const { trackProductView, trackDynamicAds } = useAnalytics()
  const { addToCart, addMultipleToCart } = useCart()
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { customer } = useCustomerDetails()
  const { setUrlParameter } = useRoutes()
  const { useProducts } = useShopify()
  const { shopifyStore } = useLocation()

  const defaultObjectState = useRef({}).current
  const defaultArrayState = useRef([]).current
  const hasMultipleVariants = product?.variants?.length > 1

  const [quantity, setQuantity] = useState(1)
  const [variant, setVariant] = useState(product.variants[0])
  const [selectedOptions, setSelectedOptions] = useState(variant?.selectedOptions || [])
  const [trackedCustomAttributes, setTrackedCustomAttributes] = useState(defaultArrayState)
  const [customAttributes, setCustomAttributes] = useState(defaultObjectState)
  const [addOns, setAddOns] = useState(defaultArrayState)
  const [adding, setAdding] = useState(false)
  const [addOnsActive, setAddOnsActive] = useState(false)
  const [popupContent, setContentPopup] = useState(false)
  const [additionalContent, setAdditionalContent] = useState(null)
  const [booking, setBooking] = useState(false)
  const [notify, setNotify] = useState(false)
  const [splitSizing, setSplitSizing] = useState(false)
  const [rushFee, setRushFee] = useState(false)
  const [sizeGuide, setSizeGuide] = useState(false)
  const [timeline, setTimeline] = useState(defaultObjectState)
  const [rushActive, setRushActive] = useState(false)
  const [trackedTimeline, setTrackedTimeline] = useState(defaultObjectState)
  const [weeks, setWeeks] = useState(null)
  const [customPaymentConfig, setCustomPaymentConfig] = useState(null)
  const [onedaypayActive, setOnedaypayActive] = useState(false)

  const sizeChart = template?.sizeChart?.find(guide =>
    guide?.table?.rows?.length > 0 ? product.productType?.toLowerCase() === guide.productType?.toLowerCase() : null
  )

  const variantSelected = variant?.id || content.selectedVariant

  const rushFees = useProducts({
    handles: [settings.products.delivery.rush.handle],
    firstVariants: 1,
  })
  const rushFeeProduct = rushFees?.length > 0 ? rushFees[0] : null
  const rushFeeAdded = rushFeeProduct && addOns && addOns.includes(rushFeeProduct)

  const parentBreadcrumb = useMemo(() => {
    if (!state?.collection && !content?.primaryCollection) return null
    return state?.collection
      ? {
          useBack: true,
          title: state.collection.title,
          url: `${settings.routes.COLLECTION}/${state.collection.shopify.handle}`,
        }
      : {
          useBack: true,
          title: content?.primaryCollection?.title,
          url: `${settings.routes.COLLECTION}/${content?.primaryCollection?.shopifyHandle}`,
        }
  }, [state, content])

  useEffect(() => {
    if (product.variants && product.variants.length > 0) {
      trackDynamicAds({
        eventName: "ViewContent",
        objectProperties: {
          content_ids: [decodeShopifyId(product.id, `Product`)],
          content_type: "product_group",
        },
      })
    }

    if (!customPaymentConfig && shopifyStore) {
      const fetchConfig = async () => {
        const result = await loadPaymentConfigs(shopifyStore)
        if (result) {
          setCustomPaymentConfig(result)
        }
      }
      fetchConfig()
    }
  }, [shopifyStore])

  useEffect(() => {
    if (customPaymentEnabled) {
      setOnedaypayActive(true)
    } else if (customPaymentUsers && Array.isArray(customPaymentUsers)) {
      const pattern = customPaymentUsers.join("|")
      const isExemptEmail = customer?.email?.match(new RegExp(pattern, "gi"))
      setOnedaypayActive(!!isExemptEmail)
    }
  }, [customer, customPaymentEnabled])

  useEffect(() => {
    const variant = product?.variants?.find(variant => variant.id === content?.selectedVariant) || product?.variants?.[0]

    if (variant) {
      setSelectedOptions(variant?.selectedOptions)
    }
  }, [loading, product?.variants?.[0]?.priceV2])

  useEffect(() => {
    if (!loading) {
      const variant =
        product?.variants?.find(
          ({ selectedOptions: variantOptions }) =>
            variantOptions?.filter(
              variantOption => variantOption.value === selectedOptions.find(selectedOption => selectedOption.name === variantOption.name)?.value
            )?.length === selectedOptions?.length
        ) || null
      setVariant(variant)
      setSplitSizing(
        selectedOptions?.filter(
          option =>
            option.name &&
            option.name.toLowerCase() === settings.products.splitSizing.sizeOptionName &&
            option.value &&
            option.value.toLowerCase().includes(settings.products.splitSizing.sizeOptionValue)
        ).length
      )
      if (variant) {
        trackProductView(activeProduct, variant)
      }
    }
  }, [loading, selectedOptions])

  useEffect(() => {
    if (rushFee && rushActive) {
      setRushActive(false)
    }
  }, [rushFee])

  useEffect(() => {
    if (splitSizing) {
      setTrackedCustomAttributes(settings.products.splitSizing.dimentions)
    } else {
      setTrackedCustomAttributes(defaultArrayState)
      setCustomAttributes(defaultObjectState)
    }
  }, [splitSizing, rushActive])

  useEffect(() => {
    if (timeline !== trackedTimeline) {
      setTrackedTimeline(timeline)
    }
  }, [weeks])

  useEffect(() => {
    if (!loading) {
      handleVariantSelection(variantSelected)
    }
  }, [variantSelected])

  const handleQuantityChange = event => {
    setQuantity(event.target.value)
  }

  const checkCustomPaymentAttribute = () => {
    if (!onedaypayActive) {
      return {}
    }

    if (product?.tags?.filter(tag => tag.includes("payment-plan:")).length > 0) {
      return {
        _onedaypay: "true",
      }
    }

    return {}
  }

  //Check if the product has a tag cutomised
  const productString = product.tags.join()
  const haveCustomisedTag = productString.includes("customised:")

  const handleAddToCart = async () => {
    setAdding(true)

    const dreampayAttribute = checkCustomPaymentAttribute()
    const mergedAttributes = { ...customAttributes, ...dreampayAttribute }
    const attributes = mergedAttributes
      ? Object.entries(mergedAttributes).map(attribute => {
          const attr = {
            key: attribute[0],
            value: attribute[1],
          }

          return attr
        })
      : []

    if (addOns.length) {
      const input = addOns.map(addOn => ({
        variantId: addOn.variants[0].id,
        quantity: 1,
        customAttributes: [
          {
            key: `_grouped`,
            value: variant.id,
          },
        ],
      }))

      input.unshift({ variantId: variant.id, quantity, customAttributes: attributes })

      await addMultipleToCart(input)
    } else {
      await addToCart(variant.id, quantity, attributes, haveCustomisedTag)
    }

    setActiveCart(true)
    setAdding(false)

    trackDynamicAds({
      eventName: "AddToCart",
      objectProperties: {
        contents: [
          {
            id: decodeShopifyId(product.id, `Product`),
            quantity,
          },
        ],
        content_type: "product_group",
        currency: checkout?.currencyCode || ``,
      },
    })
  }

  const handleVariantSelection = variantId => {
    if (!variantId || !hasMultipleVariants) return false

    window.history.replaceState(null, "", setUrlParameter(settings.params.variant, decodeShopifyId(variantId, `ProductVariant`)))
  }

  const customisedAttributes = []
  if (product?.tags?.length > 0) {
    product.tags
      .filter(tag => tag.includes(settings.products.attributesTag))
      .map(tag => {
        const label = tag.replace(settings.products.attributesTag, ``).trim()
        customisedAttributes.push({
          label,
          maxLength: 200,
          showLabel: true,
          type: `text`,
        })
      })
  }

  if (product.productType.toLowerCase() === settings.products.giftCards.type.toLowerCase()) {
    settings.products.giftCards.attributes.map(attribute => {
      customisedAttributes.push({
        ...attribute,
        showLabel: false,
      })
    })
  }

  if (customisedAttributes.length && trackedCustomAttributes.length !== customisedAttributes.length) {
    setTrackedCustomAttributes(customisedAttributes)
  }

  const timelineTag = product?.tags?.find(tag => tag.includes(settings.products.delivery.timelineTag))
  const isPreorder = product.tags.filter(tag => tag.includes(`${settings.products.purchaseTag}Pre-order`)).length > 0

  const updateAddOn = product => {
    if (addOns.includes(product)) {
      const items = addOns.filter(item => item.id !== product.id)
      setAddOns(items)
    } else {
      const items = [...addOns, product]
      setAddOns(items)
    }
  }

  const checkRushFeeOptionLimitation = product => {
    const hasEligibleTag = product.tags.find(tag => tag.includes("payment-plan:"))
    return !hasEligibleTag ? false : customPaymentConfig && customPaymentConfig.rushFeeActive
  }

  const collection = parentBreadcrumb

  const rushFeeOptionLimited = checkRushFeeOptionLimitation(product)

  const paths = [
    {
      useBack: true,
      title: product.title,
      url: `${settings.routes.PRODUCT}/${product.handle}`,
    },
  ]

  if (collection) {
    paths.splice(0, 0, collection)
  }

  Component.displayName = name
  return (
    <Component
      adding={adding}
      additionalContent={additionalContent}
      addOns={addOns}
      addOnsActive={addOnsActive}
      booking={booking}
      content={content}
      customAttributes={customAttributes}
      customisedAttributes={customisedAttributes}
      customPaymentDescription={customPaymentDescription}
      handleAddToCart={handleAddToCart}
      handleQuantityChange={handleQuantityChange}
      hasMultipleVariants={hasMultipleVariants}
      notify={notify}
      onedaypayActive={onedaypayActive}
      paths={paths}
      popupContent={popupContent}
      product={product}
      quantity={quantity}
      rushActive={rushActive}
      rushFee={rushFee}
      rushFeeAdded={rushFeeAdded}
      rushFeeProduct={rushFeeProduct}
      rushFeeOptionLimited={rushFeeOptionLimited}
      settings={settings}
      selectedOptions={selectedOptions}
      setAdditionalContent={setAdditionalContent}
      setAddOns={setAddOns}
      setAddOnsActive={setAddOnsActive}
      setBooking={setBooking}
      setContentPopup={setContentPopup}
      setCustomAttributes={setCustomAttributes}
      setNotify={setNotify}
      setRushFee={setRushFee}
      setRushActive={setRushActive}
      setSelectedOptions={setSelectedOptions}
      setSizeGuide={setSizeGuide}
      setTimeline={setTimeline}
      setWeeks={setWeeks}
      sizeChart={sizeChart}
      sizeGuide={sizeGuide}
      splitSizing={splitSizing}
      template={template}
      timeline={timeline}
      timelineTag={timelineTag}
      isPreorder={isPreorder}
      trackedCustomAttributes={trackedCustomAttributes}
      trackedTimeline={trackedTimeline}
      updateAddOn={updateAddOn}
      variant={variant}
      variantSelected={variantSelected}
      weeks={weeks}
    />
  )
}
