import React from "react"

import { Product } from "../../../../types"

import { withProductFormGiftCard } from "./withProductFormGiftCard"
import { Dropdown } from "../../Styled/Dropdown/Dropdown"
import { Wrapper } from "./ProductFormStyles"

interface Props {
  currentOption: any
  handleOptionChange: any
  label: string
  option: any
  product: Product
  type: any
}

export const ProductFormGiftCard = withProductFormGiftCard(({ currentOption, handleOptionChange, label, option, product, type }: Props) =>
  product?.productType?.toLowerCase() === type.toLowerCase() ? (
    <Wrapper>
      <Dropdown
        key={option.id}
        name={option.name}
        onChange={handleOptionChange}
        label={label ? `${label} ${option.name ? option.name.toLowerCase() : ``}` : option.name}
        options={option.values.map(({ label }) => ({
          label: label,
          value: label,
        }))}
        value={currentOption.label}
      />
    </Wrapper>
  ) : null
)
