import React from "react"

import { withProductContent } from "./withProductContent"
import { RichText } from "../../RichText/RichText"
import { DetailTabs, ProductTabContent, Tab, Tabs } from "./ProductContentStyles"

interface Props {
  description: any
  details: any
  locales: any
  process: any
  setTab: any
  tab: any
}

export const ProductContent = withProductContent(({ description, details, locales, process, setTab, tab }: Props) =>
  description || details || process ? (
    <DetailTabs>
      <Tabs>
        {description && (
          <Tab onClick={() => setTab(`description`)} active={tab === `description`}>
            {locales?.description}
          </Tab>
        )}
        {details && (
          <Tab onClick={() => setTab(`details`)} active={tab === `details`}>
            {locales?.details}
          </Tab>
        )}
        {process && (
          <Tab onClick={() => setTab(`process`)} active={tab === `process`}>
            {locales?.process}
          </Tab>
        )}
      </Tabs>

      {description && tab === `description` && (
        <ProductTabContent>
          <RichText html={description} />
        </ProductTabContent>
      )}
      {details && tab === `details` && (
        <ProductTabContent>
          <RichText content={details} />
        </ProductTabContent>
      )}
      {process && tab === `process` && (
        <ProductTabContent>
          <RichText content={process} />
        </ProductTabContent>
      )}
    </DetailTabs>
  ) : null
)
