import React from "react"

import { withProductContentSizeGuideButton } from "./withProductContentSizeGuideButton"
import { SizeGuideIcon, SizeGuide, SizeGuideButton } from "./ProductContentStyles"

interface Props {
  locales: any
  setActive: any
  sizeChart: any
}

export const ProductContentSizeGuideButton = withProductContentSizeGuideButton(({ locales, setActive, sizeChart }: Props) =>
  sizeChart ? (
    <>
      <SizeGuide>
        <SizeGuideButton onClick={() => setActive(true)}>
          <span>{locales.sizeGuideButton}</span> <SizeGuideIcon />
        </SizeGuideButton>
      </SizeGuide>
    </>
  ) : null
)
