import React, { useEffect, useRef, useState } from "react"

import { useCore } from "@hooks/useCore"
import { useCustomerDetails } from "@hooks/useCustomer"
import { useFunctions } from "@hooks/useFunctions"
import { useLanguage } from "@hooks/useLanguage"

export const withProductFormNotify = Component => ({ name = `ProductFormNotify`, active, children, product, setActive, variant }) => {
  const [sent, setSent] = useState(false)
  const [acceptsMarketing, setAcceptsMarketing] = useState(false)
  const [email, setEmail] = useState(``)
  const field = useRef()
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { customer } = useCustomerDetails()
  const { backInStock, customerSubscribe, errors, loading } = useFunctions()

  const locales = {
    ...useLanguage(`form`),
    ...useLanguage(`account`),
    ...useLanguage(`products`),
  }

  const handleSubscribe = () => setAcceptsMarketing(acceptsMarketing => !acceptsMarketing)

  const handleSubmit = async event => {
    event.preventDefault()

    const productVariant = decodeShopifyId(variant?.id, `ProductVariant`)

    if (variant) {
      const backInStockRes = await backInStock(email, productVariant)
    }

    if (acceptsMarketing) {
      await customerSubscribe(email)
    }

    setSent(true)
  }

  useEffect(() => {
    if (sent) {
      setSent(false)
    }
    if (active) {
      setTimeout(() => {
        field?.current?.select()
      }, 200)
    }
  }, [active])

  useEffect(() => {
    if (!email && customer?.email) {
      setEmail(customer.email)
    }
  }, [customer])

  Component.displayName = name
  return (
    <Component
      acceptsMarketing={acceptsMarketing}
      active={active}
      customer={customer}
      email={email}
      errors={errors}
      field={field}
      handleSubscribe={handleSubscribe}
      handleSubmit={handleSubmit}
      loading={loading}
      sent={sent}
      locales={locales}
      product={product}
      setActive={setActive}
      setEmail={setEmail}
    >
      {children}
    </Component>
  )
}
