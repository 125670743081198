import React from "react"

import { withProductUpsellRecommendations } from "./withProductUpsellRecommendations"
import { SearchItem } from "../../Search/Item/SearchItem"
import { Row } from "./ProductUpsellStyles"

interface Props {
  products: Array<any>
}

export const ProductUpsellRecommendations = withProductUpsellRecommendations(({ products }: Props) => {
  return products?.length > 0 ? (
    <Row scrollHidden={true}>
      {products?.map((product, index) => (
        <SearchItem key={product.handle} index={index} list={`Product Recommendations`} product={product} width={`1/3`} screenWidth />
      ))}
    </Row>
  ) : null
})
