import React from "react"

import { withProductContentRushCalculator } from "./withProductContentRushCalculator"
import {
  SmallCalculator,
  Header,
  RushFeeTitle,
  CloseIcon,
  CloseButton,
  Calculator,
  CalculatorButton,
  Field,
  FieldsWrapper,
  LargeCalculator,
  InputWrapper,
  ClearIcon,
  ClearButton,
  LargeCalculatorButton,
  StyledInput,
} from "./ProductContentStyles"

interface Props {
  buttonText: string
  disabledButtonText: string
  handleCalculate: any
  handleUpdate: any
  fieldRef: any
  locales: any
  products: any
  setRushActive: any
  setTimeline: any
  simple: boolean
  timeline: any
  validateTimeline: any
}

export const ProductContentRushCalculator = withProductContentRushCalculator(
  ({
    buttonText,
    disabledButtonText,
    handleCalculate,
    handleUpdate,
    fieldRef,
    locales,
    products,
    setRushActive,
    setTimeline,
    simple,
    timeline,
    validateTimeline,
  }: Props) =>
    simple ? (
      <SmallCalculator>
        <Header>
          <RushFeeTitle>{locales.rushFeeCalculatorDate}</RushFeeTitle>
          <CloseButton onClick={() => setRushActive(false)}>
            <CloseIcon />
          </CloseButton>
        </Header>

        <Calculator>
          {products.delivery.fields.map((field, i) => (
            <Field key={i} simple>
              <StyledInput
                maxLength={field.maxchars}
                name={field.name}
                placeholder={field.label.toUpperCase()}
                simple
                ref={!i ? fieldRef : null}
                onChange={event => {
                  const date = {
                    ...timeline,
                  }
                  date[event.target.name] = event.target.value

                  setTimeline(date)
                }}
                value={timeline[field.name] || ``}
              />
            </Field>
          ))}

          <CalculatorButton onClick={validateTimeline().valid ? handleCalculate : null} disabled={!validateTimeline().valid}>
            {buttonText}
          </CalculatorButton>
        </Calculator>
      </SmallCalculator>
    ) : (
      <LargeCalculator>
        <FieldsWrapper>
          {products.delivery.fields.map((field, i) => (
            <Field key={i}>
              <InputWrapper>
                <StyledInput
                  maxLength={field.maxchars}
                  name={field.name}
                  placeholder={field.label.toUpperCase()}
                  ref={!i ? fieldRef : null}
                  onChange={event => {
                    const date = {
                      ...timeline,
                    }
                    date[event.target.name] = event.target.value

                    setTimeline(date)
                  }}
                  value={timeline[field.name] || ``}
                />

                <ClearButton
                  onClick={() => {
                    const date = {
                      ...timeline,
                    }
                    date[field.name] = ``

                    setTimeline(date)
                  }}
                >
                  <ClearIcon />
                </ClearButton>
              </InputWrapper>
            </Field>
          ))}
        </FieldsWrapper>

        <LargeCalculatorButton
          onClick={validateTimeline().valid ? (validateTimeline().changed ? handleCalculate : handleUpdate) : null}
          disabled={!validateTimeline().valid}
        >
          {validateTimeline().changed ? disabledButtonText : buttonText}
        </LargeCalculatorButton>
      </LargeCalculator>
    )
)
