import React from "react"

import { withProductContentAdditional } from "./withProductContentAdditional"
import { Popup } from "../../Modals/Popup/Popup"
import { PolicyContent } from "../../Policy/Content/PolicyContent"
import { AdditionalContent, AdditionalContentHeading } from "./ProductContentStyles"

interface Props {
  active: any
  accordions: any
  content: any
  setActive: any
  title: any
}

export const ProductContentAdditional = withProductContentAdditional(({ active, accordions, content, setActive, title }: Props) => (
  <Popup active={active} setActive={setActive}>
    {title ? (
      <AdditionalContent>
        <AdditionalContentHeading>{title}</AdditionalContentHeading>
        <PolicyContent accordions={accordions} content={content} />
      </AdditionalContent>
    ) : null}
  </Popup>
))
