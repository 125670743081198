import React from "react"

import { withPolicyContent } from "./withPolicyContent"
import { P } from "../../Styled/Text"
import { RichText } from "../../RichText/RichText"
import { Accordion } from "../../Accordion/Accordion"

interface Props {
  accordions: any
  active: any
  content: string
  setActive: any
}

export const PolicyContent = withPolicyContent(({ accordions, active, content, setActive }: Props) => (
  <>
    {content ? (
      <P>
        <RichText content={content} />
      </P>
    ) : null}

    {accordions && accordions.length
      ? accordions.map(accordion => (
          <Accordion key={accordion.title} active={active === accordion.title} id={accordion.title} setActive={setActive} title={accordion.title}>
            <P>
              <RichText content={accordion.content} />
            </P>
          </Accordion>
        ))
      : null}
  </>
))
